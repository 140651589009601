<template>
  <v-btn
    @click="handleClick()"
    @mouseleave="confirm = false"
    class="my-0"
    :color="color"
    :small="small"
    :disabled="disabled"
    outlined
    :loading="loading">
    <span>{{ confirm ? $t('actions.confirm') : text }}</span>
  </v-btn>
</template>

<script>

export default {
  props: {
    text: String,
    small: Boolean,
    disabled: Boolean,
    loading: Boolean
  },
  data () {
    return {
      confirm: false
    }
  },
  computed: {
    color () {
      return this.confirm
        ? 'warning'
        : 'primary'
    }
  },
  methods: {
    handleClick () {
      if (this.confirm) {
        this.$emit('confirm')
      } else {
        this.$emit('firstClick')
        this.confirm = true
      }
    },
    clear () {
      this.confirm = false
    }
  }
}
</script>
